<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('forms.add_model', {model: $t('models.school')})}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmitLicence)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="recordData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="School Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Field: Type -->
          <validation-provider
            #default="validationContext"
            name="Type"
            rules="required"
          >
            <b-form-group
              label="Type"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="recordData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typesOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Licencia Administrativa -->
          <validation-provider
            #default="validationContext"
            name="Licencia Administrativa"
            rules="required"
          >
            <b-form-group
              label="Licencia Administrativa"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="recordData.admin_email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required|password"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-input-group>
                <b-form-input
                  id="password"
                  :type="passwordFieldType"
                  v-model="recordData.password"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
                          <div class="invalid-feedback mb-1" 
                :style="feedbackStyle($store.getters['app/currentBreakPoint'])">{{ validationContext.errors[0] }}</div>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </validation-provider>

            <!-- VALID_UNTIL -->
          <validation-provider
            #default="validationContext"
            name="valid until"
            rules="required"
          >
            <b-form-group
              label="Valid Until"
              label-for="valid_until"
              :state="getValidationState(validationContext)"
            >
              <b-form-datepicker
                id="valid_until"
                v-model="recordData.valid_until"
                class="mb-1"
                :min="minDate"
                :state="validateValidUntil"
              />
              <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Field: Community -->
          <validation-provider
          #default="validationContext"
          name="Community"
          rules=""
        >
          <b-form-group
            label="Community"
            label-for="community"
          >
            <b-form-input
              id="community"
              v-model="recordData.community"
              :state="getValidationState(validationContext)"
              
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>
          

          <!-- Field: Country -->
          <validation-provider
            #default="validationContext"
            name="Country"
            rules="required"
          >
            <b-form-group
              label="Country"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="recordData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countriesOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="country"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Field: partner -->
          <validation-provider
            #default="validationContext"
            name="Partner"
            rules="required"
          >
            <b-form-group
              label="Partner"
              label-for="payment_method_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="recordData.payment_method_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :selectable="val => val.can_select"
                :reduce="val => val.value"
                :clearable="false"
                input-id="payment_method_id"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Field: Status -->
          <validation-provider
            #default="validationContext"
            name="Payment Status"
            rules="required"
          >
            <b-form-group
              label="Payment Status"
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="recordData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Field: Licences Amount -->
          <validation-provider
          v-if="recordData.status == 'paid'"
          #default="validationContext"
          name="Licences Amount"
          rules="min:0|integer"
        >
          <b-form-group
            label="Licences Amount"
            label-for="licences_amount"
          >
            <b-form-input
              id="licences_amount"
              v-model="recordData.licences_amount"
              :state="getValidationState(validationContext)"
              type="number"
              min="0"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <!-- Field: Price per Licence -->
          <validation-provider
          v-if="recordData.status == 'paid'"
          #default="validationContext"
          name="Price Per Licence"
          rules="min:0|integer"
        >
          <b-form-group
            label="Price Per Licence"
            label-for="price_per_licence"
          >
            <b-input-group append="€">
              <b-form-input
                id="price_per_licence"
                v-model="recordData.price_per_licence"
                :state="getValidationState(validationContext)"
                type="number"
                min="0"
                step="0.01"
              />
            </b-input-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('forms.add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('forms.cancel')}}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend,
  BSidebar,BMedia, BForm, BCol, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, password, min,integer} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BInputGroup, BInputGroupAppend,
    BMedia,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormDatepicker, 
    BButton,
    vSelect,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    countriesOptions: {
      type: Array,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)


    return {
      required,
      alphaNum,
      password,
      min, 
      integer,

      minDate: tomorrow,
      statusOptions:[
        { 'value': 'promo', 'label': 'Promo' },
        { 'value': 'free', 'label': 'Free' },
        { 'value': 'paid', 'label': 'Paid' },
        { 'value': 'disable', 'label': 'Disabled' },
      ],
      typesOptions:[
        { 'value': 1, 'label': 'Público' },
        { 'value': 2, 'label': 'Privado' },
        { 'value': 3, 'label': 'Concertado' }
      ],
      passwordType: 'password'
    }
  },
  setup(props, { emit }) {
    const blankrecordData = {
      admin_email: '',
      community: '',
      country: '',
      name: '',
      password: '',
      payment_method_id: '',
      status: '',
      valid_until: null,
      type: '',
    }

    const recordData = ref(JSON.parse(JSON.stringify(blankrecordData)))
    const resetrecordData = () => {
      recordData.value = JSON.parse(JSON.stringify(blankrecordData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetrecordData)


    return {
      recordData,

      // form validation
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods:{
    feedbackStyle(bkpoint){
      return{
        'display': 'block',
        'margin-top': bkpoint === 'lg' || bkpoint === 'xl' || bkpoint === 'md' ? '21px' : '0px',
        'margin-bottom': bkpoint === 'sm' || bkpoint === 'xs' ? '21px' : '0px',
      }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async onSubmitLicence(){
      console.log(this.recordData, "submit")
      await this.$http.post('school/create', this.recordData).then( response => {
        console.log(response)
        if(response.data.status == 200){
        this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-sidebar-active', false)
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        console.log("Err -> ", error);
      })
    }
  },
  computed: {
    validateValidUntil(){
      return this.recordData.valid_until ? true : false
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
